<template>
  <Breadcrumbs></Breadcrumbs>
  <MDBCard class="m-3" :class="{ 'd-none':(edit_dialog)}">
    <MDBCardBody>
      <transition name="fade">
        <PageLoading v-if="PageLoading"></PageLoading>
      </transition>
      <MDBRow>
        <MDBCol md="2">
          <MDBInput :label="$t('COMMON.EMAIL')" :placeholder="$t('COMMON.EMAIL')" v-model="where.email" />
        </MDBCol>
        <MDBCol md="2">
          <MDBInput :label="$t('COMMON.NAME')" :placeholder="$t('COMMON.NAME')" v-model="where.name" />
        </MDBCol>
        <MDBCol class="mb-2 text-end">
          <MDBBtn color="primary" @click="GetTable">{{ $t("COMMON.SEARCH") }}</MDBBtn>
        </MDBCol>
      </MDBRow>
      <MDBCardText>
        <DataTable
          :header="headers"
          :tabledata="desserts"
          v-model:total="total"
          v-model:options="options"
          v-model:loading="loading"
        >
          <!-- 時間轉換 -->
          <template v-slot:updated_at="{ item }">
            {{ Common.TimeFormat(item.created_at) }}
          </template>
          <template v-slot:name="{ item }">
            {{ (item.first_name)?`${item.last_name}${item.first_name}`:item.name }}
          </template>
          <!-- 操作template -->
          <!-- <template v-slot:uuid="{ item }">
            <a @click="EditUUid = item.uuid" data-bs-toggle="tooltip" :title="$t('MANAGERS.EDIT-ITEM')" href="javascript:void(0)" class="me-2">
              <MDBIcon icon="edit" iconStyle="fas" class="text-primary"/>
            </a>
          </template> -->
          <template v-slot:edit="{ item }">
            <MDBBtn color="info" @click="EditUUid = item.uuid" class="px-3 py-2">
              <MDBIcon icon="edit" iconStyle="fas" class="text-white"/>
            </MDBBtn>
          </template>
        </DataTable>
      </MDBCardText>
    </MDBCardBody>
  </MDBCard>
  <DealerHomeForm v-model="edit_dialog" v-model:uuid="EditUUid"></DealerHomeForm>
</template>

<style scope>
  .form .select-option-icon {
    width: 100%;
    height: auto;
  }
  .form .rounded-circle {
    border-radius: unset!important;
  }
  .form .select-option {
    margin: 5px 0px;
  }
  .form .select-option-text {
    /*display: none;*/
  }
</style>

<script>
import { MDBCard, MDBCardBody, MDBCardText, MDBIcon, MDBBtn, MDBRow, MDBCol, MDBInput } from 'mdb-vue-ui-kit';
import DataTable from '@/components/Plugin/DataTable';
import Breadcrumbs from '@/components/Breadcrumbs';
import DealerHomeForm from '@/components/Form/Dealer/DealerHomeForm';
import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
import PageLoading from '@/components/Elements/PageLoading';
import { ref, reactive, inject } from "vue";
import { useI18n } from 'vue-i18n';

export default {
  name: "DealerManage",
  components: {
    Breadcrumbs,
    MDBCard,
    MDBCardBody,
    MDBCardText,
    DataTable,
    MDBIcon,
    DealerHomeForm,
    PageLoading,
    MDBBtn,
    MDBRow,
    MDBCol,
    MDBInput
  },
  setup() {
    const i18n = useI18n();
    const PageLoading = ref(true);
    const loading = ref(false);
    const EditUUid = ref(""); 
    const edit_dialog = ref(false);
    const ImageItems = reactive([]);
    const swipers = reactive([]);
    const Images = reactive({});
    const Products = reactive([]);
    const options = reactive({
      page: 1,
      itemsPerPage: 10,
      sortBy: ['created_at'],
      sortDesc: ['desc'],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false
    });
    const where = reactive({
      email: "",
      name: "",
      status: "",
    });
    const headers = reactive([
      {
        text: i18n.t("COMMON.SEQUENCE"),
        align: "start",
        sortable: false,
        value: "no",
        width: 50,
        class: "text-center"
      },
      { text: i18n.t("COMMON.DEALERNAME"), value: "name" ,class: "text-center"},
      { text: i18n.t("COMMON.DEALERACCOUNT"), value: "email" ,class: "text-center"},
      { text: i18n.t("COMMON.UPDATE_AT"), value: "updated_at" ,class: "text-center"},
      { text: i18n.t("COMMON.EDIT"), value: "edit", sortable: false ,class: "text-center"}
    ]);
    const desserts = ref([]);
    const total = ref(0);
    const Common = CommonService;
    const APIURL = inject('APIURL');

    const GetImage = function() {
      return new Promise(resolve => {
        ApiService.query(
          "/Admin/v1/Image",
          {}
        ).then(response => {
          if (response.status == 200) {
            response.data.data.map(record => {
              ImageItems.push({
                text: `${record.filename}.${record.extension}`,
                value: record.id,
                icon: `${APIURL}/storage/${record.directory}/${record.filename}.${record.extension}`
              });
            });
            resolve(response);
          }
        });
      });
    };

    const GetTable = function() {
      return new Promise(resolve => {
        loading.value = true;
        ApiService.query(
          "/Admin/v1/DealerSettings",
          {
            params: Object.assign(CommonService.SortHandleObject(options), where) 
          }
        ).then(response => {
          if (response.status == 200) {
            desserts.value = CommonService.DataNoHandle(
              response.data.data.data,
              options.page,
              options.itemsPerPage
            );
            total.value = response.data.data.total;
            resolve(response);
          }
        });
      });
    };

    const GetProduct = function() {
      return new Promise(resolve => {
        ApiService.query(
          "/Admin/v1/Product",
          {
            params: {
              page: 1,
              limit: -1,
              sortBy: ["created_at"],
              sortDesc: [],
            }
          }
        ).then(response => {
          if (response.status == 200) {
            response.data.data.data.map(item => {
              Products.push(item);
            });
            resolve(response);
          }
        });
      });
    };

    return {
      PageLoading,
      loading,
      EditUUid,
      edit_dialog,
      ImageItems,
      swipers,
      options,
      headers,
      desserts,
      Common,
      total,
      GetImage,
      GetTable,
      GetProduct,
      Images,
      Products,
      where
    }
  },
  provide () {
    return {
      GetTable: this.GetTable,
      Images: this.Images,
      Products: this.Products
    }
  },
  mounted() {
    Promise.all([
      this.GetTable(),
      this.GetImage(),
      this.GetProduct()
    ])
    .then(() => {
      this.PageLoading = false;
    }).catch(error => {
      console.log(error);
    });
  },
  watch: {
    options: {
      handler() {
        this.GetTable();
      },
      deep: true
    }
  }
};
</script>
